(function ($) {
    $(document).ready(function () {
        $(document).ready(function () {
            var date = new Date();
            $('#date').text(date.getFullYear());
        });

        // Animated Scroll To Section
        $(window).on('load', function () {
            var scroll = {
                windowWidth: function () {
                    return $(window).width();
                },
                offset: {
                    mobile: 30,
                    desktop: 0
                },
                changeUri: function () {
                    $('a[data-scroll="section-another-page"]').each(function () {
                        var str = $(this).attr('href');
                        var hash = str.substr(str.search('#') + 1, str.length - 1);
                        var newURI = function () {
                            var parameters = {
                                section: hash
                            };
                            return str.replace('#' + hash, '') + '?' + $.param(parameters);
                        };
                        $(this).attr('href', newURI());
                    });
                },
                animateToSection: function (hash) {
                    this.closeMenu();
                    var offset;
                    if (this.windowWidth() < 768) {
                        offset = this.offset.mobile;
                    } else if (this.windowWidth() >= 768) {
                        offset = this.offset.desktop;
                    }
                    $('html, body').animate({
                        scrollTop: $('#' + hash).offset().top - offset
                    }, 500, function () {
                        $(this).clearQueue();
                    });
                },
                closeMenu: function () {
                    $('.navbar-toggle').addClass('collapsed').attr('aria-expanded', 'false');
                    $('#navbar-collapse').removeClass('in').attr('aria-expanded', 'false');
                }
            }

            if (window.location.search) {
                var str = window.location.search.replace('?section=', '');
                setTimeout(function () {
                    scroll.animateToSection(str);
                }, 0);
            }

            if ($('a[data-scroll="section-another-page"]').length > 0) {
                scroll.changeUri();
            }

            $('body').on('click', 'a[data-scroll="section-page"]', function (event) {
                event.preventDefault();
                var str = $(this).attr('href');
                var hash = str.substr(str.search('#') + 1, str.length - 1);
                scroll.animateToSection(hash);
            });
        });
        // End Animated Scroll To Section

        $('.skills').waypoint(function () {
            $('.chart').each(function () {
                $(this).easyPieChart({
                    size: 140,
                    animate: 1500,
                    lineCap: 'butt',
                    scaleColor: false,
                    barColor: '#53AFCD',
                    trackColor: 'transparent',
                    lineWidth: 5
                });
            });
        }, {
            offset: '80%'
        });

        wow = new WOW({
            mobile: false
        });
        wow.init();

    });
})(jQuery);