(function ($) {
    $(document).ready(function () {
        function introHeight() {
            $('#gps-na-rower').css('height', $(window).height());
            positionIntroWrapper();
        }

        function positionIntroWrapper() {
            $('.intro__wrapper').css({
                bottom: 'auto',
                height: 'auto',
                top: 'auto'
            });
            var $height = $('.intro__wrapper').outerHeight();
            $('.intro__wrapper').css({
                bottom: 0,
                height: $height,
                top: 50
            });
        }

        introHeight();

        $(window).on('resize', function () {
            introHeight();
        });
    });
})(jQuery);