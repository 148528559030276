(function ($) {
    $(document).ready(function () {
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                $('.scroll-up').fadeIn('fast');
            } else {
                $('.scroll-up').fadeOut('fast');
            }
        });
    });
})(jQuery);