(function ($) {
    $(document).ready(function () {
        $('#carousel').carousel({
            interval: 5000
        });

        function calcHeight() {
            var $height = 0;
            var $el = $('#carousel .carousel-inner > .item');
            $el.css('height', 'auto');
            $el.each(function () {
                if ($height < $(this).outerHeight()) {
                    $height = $(this).outerHeight();
                }
            });

            $el.css('height', $height);
        }

        calcHeight();

        $(window).on('resize', function () {
            calcHeight();
        });
    });
})(jQuery);