(function ($) {
    $(document).ready(function () {
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                $('#navbar').addClass('navbar-shrink');
            } else {
                $('#navbar').removeClass('navbar-shrink');
            }
        });
    });
})(jQuery);